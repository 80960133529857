@import "../../../../../../../../../../style/variables.scss";

.wrapper {
    --width-in-mm: 113;
    --height-in-mm: 156; // A6 WITH bleed

    width: 100%;
    max-width: 460px;
    aspect-ratio: calc(var(--width-in-mm) / var(--height-in-mm));
    position: relative;
    container-type: size;

    --one-mm-in-width: calc(1 / var(--width-in-mm) * 100);
    --one-mm-in-height: calc(1 / var(--height-in-mm) * 100);
    --bleedSize: calc(var(--one-mm-in-width) * 4cqw);

    .innerWrapper {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: var(--color-background);
    }

    &.preview {
        pointer-events: none;
        user-select: none;
    }

    &.hideBleed {
        .innerWrapper {
            --x_width: calc(var(--one-mm-in-width) * 4%);
            --x_width_inverted: calc(100% - var(--x_width));
            --y_height: calc(var(--one-mm-in-height) * 4%);
            --y_height_inverted: calc(100% - var(--y_height));
            clip-path: polygon(
                var(--x_width) var(--y_height),
                var(--x_width_inverted) var(--y_height),
                var(--x_width_inverted) var(--y_height_inverted),
                var(--x_width) var(--y_height_inverted)
            );
            // To make rounded corners work this needs to be fixed somewho. GOogled a lot and its hard to add corunded corners to polygon. At least without svg object, probably the way to go. Or an aditional element maybe.
        }

        &.shadow {
            box-shadow: none;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                content: "";
                width: calc(100% - var(--bleedSize) * 2);
                height: calc(100% - var(--bleedSize) * 2);
                margin: var(--bleedSize);
                z-index: 1;
                box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
            }
        }
    }

    &.bleedMarker {
        &:after {
            position: absolute;
            top: 0;
            pointer-events: none;
            content: "";
            width: 100%;
            height: 100%;
            border: var(--bleedSize) solid rgba(0, 0, 0, 0.15);
            z-index: 1;
        }

        &:before {
            position: absolute;
            top: 0;
            pointer-events: none;
            content: "";
            width: 100%;
            height: 100%;
            outline: 2px dashed rgba(0, 0, 0, 0.4);
            outline-offset: calc(var(--bleedSize) * -1);
            z-index: 1;
        }
    }

    &.cutRounded {
        border-radius: calc(var(--one-mm-in-width) * 8cqw);

        .innerWrapper {
            border-radius: calc(var(--one-mm-in-width) * 8cqw);
        }

        &.bleedMarker:after {
            border-radius: calc(var(--one-mm-in-width) * 8cqw);
        }

        &.bleedMarker:before {
            border-radius: calc(var(--one-mm-in-width) * 8cqw);
        }
    }

    &.shadow {
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    }
}
