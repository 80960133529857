@import "../../../style/variables.scss";

.wrapper {
    .label {
        margin-bottom: 2px;
    }

    input {
        border-radius: 3px;
        font-family: $font-standard;
        border: 1px solid #575757;
        color-scheme: dark;
        width: 100%;
        padding: $baseline / 2;
        font-size: $baseline;
        background: transparent;
        color: #ddd;
        transition: border 0.1s ease-in;

        &:valid ~ [data-invalid-feedback] {
            display: none;
        }

        &:hover {
            border: 1px solid #828282;
        }

        &:focus {
            border: 1px solid #828282;
            outline: 0;
        }
    }

    :global(.invalidFeedback:not(:empty)) {
        color: $color-form-error;
        margin-top: 3px;
        font-size: 12px;
    }
}
